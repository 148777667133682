import React, { FC, useEffect, useMemo } from 'react';
import Moment from 'react-moment';
import { SiteContent, URL } from '../../appConstants';
import { BlockContent, Breadcrumbs, DateComp, HTMLContent, Tag } from '../../components';
import { cropHref, cropHrefs } from '../../helpers';
import { useRequest } from '../../hooks';
import { TBacicReq, TMedia } from '../../types';
import styles from './styles.module.scss';

type Props = {}

const DetailPage:FC<Props> = () => {
  const { DocTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  const id = cropHrefs().pop();

  const { data: Article, request: getArticle } = useRequest<TMedia>();
  useEffect(() => {
    if (!!id) getArticle({
      url: URL.ARTICLE.GET_ID(+id),
    });
  }, [id]);

  const { data: News, request: getNews } = useRequest<TBacicReq<TMedia>>();
  useEffect(() => {
    getNews({
      url: URL.ARTICLE.GET,
      params: {
        limit: 3,
      }
    });
  }, []);

  const Articles = useMemo(() => News?.results, [News])

  const RightArticle = ({item}:{item: TMedia}) => {
  const {
    publish_date,
    tag,
    title,
    id,
  } = item

  return (
    <a
      className={styles.last}
      href={`/media/${id}`}
    >
      <div>
        {publish_date &&
          <DateComp><Moment format='DD.MM.YYYY' date={publish_date}/></DateComp>}
        <Tag className={styles.tag}>{tag.title}</Tag>
      </div>
      <span>{title}</span>
    </a>
  )}

  return (
    <>
      <Breadcrumbs
        additional={Article?.title}
      />
      <BlockContent
        contentClass={styles.content}
        titleClass={styles.blockTitle}
        title={Article?.title}
      >
        <div className={styles.article}> 
          <div className={styles.meta}>
            {Article?.publish_date && <DateComp className={styles.date}>
              <Moment format='DD.MM.YYYY' date={Article?.publish_date}/>
            </DateComp>}
            <Tag className={styles.tag}>{Article?.tag.title}</Tag>
          </div>

          <img
            className={styles.poster}
            src={Article?.text_photo}
            alt={Article?.title}
          />

          <HTMLContent
            content={Article?.description || ''}
          />
        </div>

        <div className={styles.lastAddedList}>
          <h3>Другие новости:</h3> 
          {Articles?.map((item,id) => 
            <RightArticle
              key={id}
              item={item}
            />
          )}
        </div>

       </BlockContent>
    </>
  );
};

export default DetailPage;