import React, { FC, PropsWithChildren, useState } from 'react';
import FeedbackForm from '../../FeedbackForm';
import SidePopUp from '../Base';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  openerTitle?: string,
  popUpTitle?: string,
  buttonClassName?: string,
}

const FeedbackPopUp:FC<PropsWithChildren<Props>> = ({
  openerTitle = 'Получить консультацию',
  popUpTitle = 'Получить консультацию',
  buttonClassName,
}) => {
  const [ isShow, setIsShow ] = useState<boolean>(false);

  return (
    <>
      <div
        onClick={() => setIsShow(true)}
        className={cx(
          styles.openerButton,
          buttonClassName,
        )}
      >
        {openerTitle}
      </div>

      <SidePopUp
        visible={isShow}
        title={popUpTitle}
        classPopUp={styles.feedbackWrap}
        onClose={() => setIsShow(false)}
      >
        <FeedbackForm
          formStyle='small'
        />
      </SidePopUp>
    </>
  );
};

export default FeedbackPopUp;