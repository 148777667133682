import React, { FC, useEffect, useState } from 'react';
import MenuLinks from './MenuLinks';
import Burger from '../Burger';
import styles from './styles.module.scss';

const Menu:FC = () => {
  const [ burgerActive, setBurgerActive ] = useState(false);

  useEffect(() => {
    if (burgerActive) {
      document.body.classList.add(styles.bodyFixed);
    } else {
      document.body.classList.remove(styles.bodyFixed)
    }
  }, [burgerActive])

  return (
    <>
      <Burger
        isActive={burgerActive}
        onClick={() => setBurgerActive(!burgerActive)}
      />

      <MenuLinks
        openMobile={burgerActive}
        onClick={() => setBurgerActive(false)}
      />
    </>
  );
};

export default Menu;