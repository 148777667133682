import React, { FC, PropsWithChildren } from 'react';
import fillerImg from '../../assets/images/fillers/_FillerImage2.jpg';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  src?: string,
  alt?: string,
  title?: string,
}

const Poster:FC<PropsWithChildren<Props>> = ({
  className,
  src,
  alt,
  title,
  children,
}) => {
  return (
    <div className={cx(styles.poster, className)}>
      {children || <img
        src={src || fillerImg}
        alt={alt}
        title={title}
        />
      }
    </div>
  );
};

export default Poster;