import React, { FC } from 'react';
import { cropStr } from '../../helpers';
import styles from './styles.module.scss';

type Props = {
  title?: string,
  fileLink?: string,
}

const JuricDoc:FC<Props> = ({
  title = 'Название файла не указано',
  fileLink = '',
}) => {
  return (
    <a
      className={styles.externalDoc}
      href={fileLink}
      title={title}
      target="_blank"
      rel="noreferrer"
    >
      {cropStr(title, 85)}
    </a>
  );
};

export default JuricDoc;