import React, { FC, useState } from 'react';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';
import SearchLine from './SearchLine'
import { RouteNames } from '../../router';
import styles from './styles.module.scss';


type Props = {}

const Search:FC<Props> = () => {
  const [ openSearch, setOpenSearch] = useState<boolean>(false);
  
  const relocateHandler = () => window.location.href = RouteNames.SEARCH;

  const openHandler = () => {
    !isMobile
      ? setOpenSearch(!openSearch)
      : relocateHandler();
  }


  return (
    <div className={styles.searchWrapper}>
      <div className={cx(
        styles.searchSwitcher,
        {[styles.close]: openSearch},
      )}>
        <b
          className={styles.mobile}
          onClick={relocateHandler}
        />
        <b
          className={styles.desktop}
          onClick={openHandler}
        />
      </div>

      {openSearch && 
        <SearchLine
          className={styles.topSearch}
        />
      }
    </div>
  );
};

export default Search;
export { default as SearchLine } from './SearchLine';