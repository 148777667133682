import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  item?: string,
  title?: string,
  placeholder?: string,
  value?: string | number,
  onChange: (e: React.ChangeEvent<any>) => void,
  error?: string,
  max?: number,
  reqired?: boolean,
  items: string[],
}

const Select:FC<Props> = ({
  item,
  className,
  placeholder,
  value,
  onChange = () => {},
  error,
  max = 250,
  reqired = false,
  items,
}) => {
  return (
    <div className={cx(
      styles.field,
      className
    )}>
      <select
        className={cx({[styles.filled]: !!value})}
        name={item}
        id={item}
        onChange={onChange}
        value={value}
        required={reqired}
      >
        {placeholder && <option value='' disabled defaultValue=''>{placeholder?.concat(reqired ? '*' : '')}</option>}
        {items.map((profile, id)=>
          <option
            key={id}
            value={profile}
          >
            {profile}
          </option>
        )}
      </select>
      
      {error &&
        <div className={styles.error}>{error}</div>
      }
    </div>
  );
};

export default Select;