import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import mainImg from '../../assets/images/main-poster.png';
import { FeedbackPopUp } from '../SidePopUp';
import { BigPoster } from '../../components';

type Props = {
  title: string,
  className?: string,
  contentClassName?: string,
  childrenClassName?: string,
}

const TitleWContent:FC<PropsWithChildren<Props>> = ({
  title,
  className,
  contentClassName,
  childrenClassName,
  children,
}) => {
  return (
    <div className={cx(
      styles.titleWithContent,
      className,
    )}>
      <div className={cx(
        styles.content,
        contentClassName,
      )}>
        <h1>{title}</h1>
        <div className={cx(
          styles.children,
          childrenClassName,
        )}>
          {children}
        </div>

        <BigPoster
          className={styles.picForMobile}
          image={mainImg}
        />

        <FeedbackPopUp
          openerTitle='Получить консультацию'
        />
      </div>

      <BigPoster
        className={styles.picForDesktop}
        image={mainImg}
      />
    </div>
  );
};

export default TitleWContent;