// author: Victor K.
import CFSExtLink from '../CFSExtLink';
import styles from './styles.module.scss';

const Copyright = () => {
  const s = 2022;
  const f = new Date().getFullYear();
  return (
    <div className={styles.copy}>
      <div>{s}{f !== s && ` - ${f}`}, &copy; Все права защищены</div>
      <CFSExtLink />
    </div>
  )
}

export default Copyright;
