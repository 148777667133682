import React, { FC, PropsWithChildren, ReactElement } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  id?: string,
  wrapperClass?: string,
  contentClass?: string,
  titleClass?: string,
  title?: string,
  customTitle?: ReactElement,
  descr?: string,
}

const BlockContent:FC<PropsWithChildren<Props>> = ({
  id,
  wrapperClass,
  contentClass,
  titleClass,
  title,
  customTitle,
  descr,
  children,
}) => {
  return (
    <div id={id} className={cx( wrapperClass, styles.blockWrapper )} >
      <div className={cx( contentClass, styles.blockContent )} >
        {((title || customTitle) || descr) &&
          <>
            {title && <div className={cx(titleClass, styles.blockTitle)}>
              <h2>{title}</h2>
              {customTitle}
            </div>}
            {descr &&
              <div className={styles.blockDescr}>{descr}</div>}
          </>
        }
        {children}
      </div>
    </div>
  );
};

export default BlockContent;