import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  onClick?: () => void,
  className?: string,
}

const Tag:FC<PropsWithChildren<Props>> = ({
  onClick = () => {},
  className,
  children,
}) => {
  return (children ?
    <div
      className={cx(styles.tag, className)}
      onClick={onClick}
    >
      {children}
    </div> : <></>
  );
};

export default Tag;