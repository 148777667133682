import React, { FC, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import fillerImg from '../../assets/images/fillers/_FillerImage2.jpg';
import {
  Tag,
  DateComp,
  // Poster,
  HTMLContent,
} from '../../components';
import { MediaPopUp } from '../../containers';
import { cropStr } from '../../helpers';
import { TItem, TOpener } from '../../types';
import styles from './styles.module.scss';

type Props = {
  item?: TItem,
  type?: TOpener,
  shortCard?: boolean,
}

const TESTData:TItem = {
  id: 1,
  image: fillerImg,
  title: "Default, Предприниматели могут узнать о налоговых льготах, отсрочках и снижении ставок по аренде, субсидиях и грантах, которые доступны конкретно в их случае.",
  date: '15 апреля 2022',
  link: '',
  tags: ['Пустой тэг'],
}

const MediaItem:FC<Props> = ({
  item = TESTData,
  type = 'div',
  shortCard = false,
}) => {
  const {
    id,
    image,
    photo,
    date,
    title,
    description,
    link,
    tag,
  } = item;

  const Content:FC<{
    onClick?: () => void,
  }> = ({
    onClick = () => {},
  }) => <div
    onClick={() => onClick()}
  >

    {title && <h3 className={styles.title} title={title}>
      {cropStr(title, 150)}
    </h3>}


    {description &&
      <HTMLContent 
        content={description}
      />
    }

    {/* {(!shortCard && (date || tags?.length)) && <div className={styles.details}>  */}
    <div className={styles.details}> 
      <DateComp>
        <Moment
          format='DD.MM.YYYY'
          date={date}
        />
      </DateComp>
      {tag && <Tag>{tag.title}</Tag>}
    </div>
  </div>

  

  return (<>
    <Link
      to={`/media/${id}`}
      className={styles.mediaItem}
    >
      <Content />
    </Link>
  </>);
};

export default MediaItem;