import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SiteContent, URL } from '../../appConstants';
import { AccentBlock, ArrowLink, BlockContent, QuoteText } from '../../components';
import { Banners, BigTitleBlock, MainMediaGrid, TitleWContent } from '../../containers';
import serv1 from '../../assets/images/icon_serv_1_100x.svg';
import serv2 from '../../assets/images/icon_serv_2_100x.svg';
import { useRequest } from '../../hooks';
import { TBacicReq, TMainData } from '../../types';
import styles from './styles.module.scss';
import { Filt_Active } from '../../helpers';

type Props = {}

const AccentBlocks = (data?: TMainData) => [
  {
    accent: data?.numbers_minimum_security,
    text: 'Минимальное обеспечение ежегодного роста производительности на средних и крупных предприятиях Москвы',
  },{
    accent: data?.number_urban_enterprises,
    text: 'Городское предприятие станет его участником к 2024 году',
  },
]

const Services = [
  {
    title: 'Адресная поддержка',
    link: '/targeted-support',
    img: serv1,
  },{
    title: 'Системные меры поддержки',
    link: '/systemic-support',
    img: serv2,
  },
];

const MainPage:FC<Props> = () => { 
  const { DocTitle } = SiteContent.PagesInfo['/'];
  document.title = DocTitle;
  window.scrollTo(0,0);

  const { data, request } = useRequest<TBacicReq<TMainData>>();
  useEffect(() => {
    request({ url: URL.MAIN_PAGE.GET })
  }, [])

  return (
    <>
      <BlockContent>
        <TitleWContent
          title='Агентство стратегического развития Москвы'
          className={styles.title}
          contentClassName={styles.titleContent}
        >
          Мы за качественные изменения экономики Москвы
        </TitleWContent>

        <BigTitleBlock
          title='О компании'
          link='/about'
          contentClassName={styles.aboutText}
          >
          <div className={styles.text}>
            {data?.results[0].description_about_company}
          </div>
          <div className={styles.blocks}>
            {AccentBlocks(data?.results[0]).map((item, id) => 
              <AccentBlock
                key={id}
                accent={item.accent || ''}
                text={item.text}
              />
            )}
          </div>
        </BigTitleBlock>

        <BigTitleBlock
          title='Услуги'
          contentClassName={styles.uslugi}
        >
          {Services.map((item, id) => 
            <Link
              className={styles.usluga}
              key={id}
              to={item.link}
            >
              <img src={item.img} alt="" />
              <div className={styles.serviceName}>{item.title}</div>

              <div className={styles.link}>
                <ArrowLink
                  name='Подробнее'
                  link={item.link}
                  notHover
                />
              </div>
            </Link>
          )}
        </BigTitleBlock>

        <BigTitleBlock
          title='Новости'
          link='/media'
          contentClassName=''
        >
          <MainMediaGrid />
        </BigTitleBlock>

        <BigTitleBlock>
          <QuoteText
            quote={data?.results[0].quote_title}
            text={data?.results[0].quote_text}
            img={data?.results[0].quote_image}
            position={data?.results[0].quote_position}
            author={data?.results[0].quote_full_name}
          />
        </BigTitleBlock>

        <Banners
          data={Filt_Active(data?.results[0].banner)}
        />
      </BlockContent>

    </>
  );
};

export default MainPage;
