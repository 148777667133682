import React, { FC } from 'react';
import Moment from 'react-moment';
import { DateComp, ExternalLink, HTMLContent, Tag } from '../../components';
import { TEvent } from '../../types';
import EventCard from '../EventCard';
import styles from './styles.module.scss';

type Props = {
  events?: TEvent[],
  selectedDate?: Date,
}

const EventsList:FC<Props> = ({
  events,
  selectedDate,
}) => {
  const NoData = () => <>
    <div className={styles.meta}>
      <DateComp className={styles.date}>
        <Moment
          format='DD.MM.YYYY'
          date={selectedDate}
        />
      </DateComp>
    </div>
    <div className={styles.noData}>
      <span>На текущую дату нет никаких мероприятий</span>
    </div>
  </>


  return (
    <div className={styles.eventContent}>
      {events?.length
        ? events.map((item, id) => <div key={id}>
          <div className={styles.meta}>
            <DateComp className={styles.date}>
              <Moment
                format='DD.MM.YYYY'
                date={selectedDate}
              />
            </DateComp>
            <Tag className={styles.tag}>{item.tag}</Tag>
            {item.link && <ExternalLink
              className={styles.link}
              fileLink={item.link}
              title='Перейти к мероприятию'
            />}
          </div>
          <EventCard
            item={item}
            className={styles.cardPadding}
          />
        </div>)
        : <NoData />
      }
    </div>
  );
};

export default EventsList;