/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  content: string,
};

const HTMLContent: FC<Props> = ({ content = '', className }) => (
  <div
    className={cx(styles.section, className)}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

export default HTMLContent;
