import React, { FC, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import styles from './styles.module.scss';

type Props = {
  max?: string,
  cur?: string,
}

const RadialChart:FC<Props> = ({
  max = '1',
  cur = '0',
}) => useMemo(() => {
  return (
    <div className={styles.chart}>
      <div className={styles.donutWrapper}>
        <div className={styles.donut}>
          <Doughnut
            width={100}
            height={100}
            data={{datasets: [{
                data: [+cur, (+max - +cur)],
                backgroundColor: ["#f9b445", '#f4f4f4'],
                borderWidth: 0,
              }]
            }}
          />
        </div>
      </div>
      {+max>1 && <div className={styles.donutLegend}>
        <h3><b>{cur}</b>/{max}</h3>
        Московских предприятий уже<br/>участвуют в программе<br/>(в 2022 году)
      </div>}
    </div>
  );
}, [cur, max]);

export default RadialChart;