import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
}

const DateComp:FC<PropsWithChildren<Props>> = ({
  className,
  children,
}) => {
  return (children ?
    <div className={cx(styles.date, className)}>
      {children}
    </div> : <></>
  );
};

export default DateComp;