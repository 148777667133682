import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  page: number,
  count: number,
  pageSize: number,
  onChange: (id: number) => void, 
}

const PageSwitcher:FC<Props> = ({
  page,
  count,
  pageSize,
  onChange,
}) => {
  const pages = Math.ceil(count / pageSize);

  return pages > 1 ? (
    <div className={styles.mainPaginator}>
      <div className={styles.arrows}>
        <div
          className={cx(
            styles.arrow,
            styles.left,
          )}
          onClick={() => {if (page > 0) onChange(--page)}}
        />
        <div
          className={cx(
            styles.arrow,
            styles.right,
          )}
          onClick={() => {if (page < pages-1) onChange(++page)}}
        />
      </div>

      <div className={styles.dots}>
        {[...Array(pages)].map((x, id) => 
          <div
            key={id}
            className={cx(
              styles.dot,
              {[styles.active]: id === page},
            )}
            onClick={() => onChange(id)}
          />
        )}
      </div>
    </div>
  ) : <></>
};

export default PageSwitcher;