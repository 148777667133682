// import { isLocalTest } from "../utils";

export const cropLink = (link: string) => {
  return link.split('.').pop() || '';
};

export const cropStr = (
  str: string,
  maxLength: number,
  cropCenter: boolean = false
) => {
  if (str.length > maxLength) {
    return cropCenter
      ? `${str.substring(0, maxLength/1.75)}...${str.substring(str.length-maxLength/3)}`
      : str.substring(0, maxLength-3).concat('...');
  } 
  return str;
};

export const cropHrefs = () => {
  return window.location.pathname.split('/').filter(item => !!item);
}

export const cropHref = () => {
  return '/'.concat(cropHrefs()[0]);
}