import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  link: string,
  name?: string,
  notHover?: boolean,
}

const ArrowLink:FC<Props> = ({
  link,
  name = 'Перейти в раздел',
  notHover = false,
}) => {
  return (
    <Link
      className={cx(
        styles.styledLink,
        {[styles.hoverable]: !notHover},
      )}
      to={link}
    >
      {name}
    </Link>
  );
};

export default ArrowLink;