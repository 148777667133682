import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../router';

type Props = {
  title?: string,
  className?: string,
}

const PolicyBtn:FC<Props> = ({
  title,
  className
}) => {
  return (
    <Link
      className={className}
      to={RouteNames.POLICY}
    >{title || 'Политика конфиденциальности'}</Link>
  );
};

export default PolicyBtn;