import React, { FC, useState } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import fillerImg from '../../assets/images/fillers/_FillerImage2.jpg';
import {
  Tag,
  DateComp,
  Poster,
} from '../../components';
import { MediaPopUp } from '../../containers';
import { cropStr } from '../../helpers';
import { TItem, TOpener } from '../../types';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  item?: TItem,
  type?: TOpener,
  shortCard?: boolean,
}

const TESTData:TItem = {
  id: 1,
  image: fillerImg,
  title: "Default, Предприниматели могут узнать о налоговых льготах, отсрочках и снижении ставок по аренде, субсидиях и грантах, которые доступны конкретно в их случае.",
  date: '15 апреля 2022',
  link: '',
  tags: ['Пустой тэг'],
}

const MediaItem:FC<Props> = ({
  item = TESTData,
  type = 'div',
  shortCard = false,
}) => {
  const {
    image,
    photo,
    date,
    title,
    descr,
    link,
    tag,
  } = item;

  const [ isShowDetail, setIsShowDetail ] = useState<boolean>(false);

  const Content:FC<{
    titleClassName?: string,
    onClick?: () => void,
  }> = ({
    titleClassName,
    onClick = () => {},
  }) => {
    console.log(date);
    return (
      <div
        onClick={() => onClick()}
      >
        <Poster
          src={image || photo}
          alt={title}
          title={title}
          />
        
        {(!shortCard && (date || tag)) && <div className={styles.details}> 
          <DateComp><Moment format='DD.MM.YYYY' date={date}/></DateComp>
          {tag && <Tag>{tag.title}</Tag>}
        </div>}

        {title && <h3
          className={cx(
            styles.title,
            titleClassName
          )}
          title={title}
        >
          {cropStr(title, 150)}
        </h3>}

        {descr && <div className={styles.descr}>{descr}</div>}
      </div>
    )
  }

  

  return (<>
    {type === 'link' &&
      <Link
        to={link || `/media/${item.id}`}
        className={styles.mediaItem}
      >
        <Content />
      </Link>}


    {type === 'gallery' &&
      <Link
        to={link || `/gallery/${item.id}`}
        className={styles.mediaItem}
      >
        <Content />
      </Link>}


    {type === 'extlink' &&
      <a
        href={link || ''}
        target='_blank'
        rel='noreferrer'
        className={styles.mediaItem}
      >
        <Content />
      </a>}

    {type === 'div' &&
      <div className={styles.mediaItem}>
        <Content
          onClick={() => setIsShowDetail(true)}
          titleClassName={styles.fixedTitle}
        />
        <MediaPopUp
          visible={isShowDetail}
          title={title}
          item={item}
          onClose={() => setIsShowDetail(false)}
        />
      </div>
    }
  </>);
};

export default MediaItem;