import { TStringsObj } from "../types";

interface ItemsWithPrior extends TStringsObj {
  priority?: string,
  sort?: number,
  is_active?: boolean,
  active?: boolean,
}

export const byPrior = (DataArray:ItemsWithPrior[], maxPrior:number = 10) => {
  const newArray = [];
  for (let i = 0; i <= maxPrior; i++) {
    const mid = DataArray.filter(item => (
      (item.priority && +item.priority === i)
      || item.sort === i
    ));
    if (!!mid.length) newArray.push(...mid)
  }
  return newArray.filter(item => (item.is_active || item.active));
};