// author: Victor K.
import { TStringsObj } from '../types';

export const InputErrors:TStringsObj = {
  min: (num:number) => `Не может быть короче ${num} символов`,
  max: (num:number) => `Не может быть длиннее ${num} символов`,
  empty: 'Поле не может быть пустым',
  full_name: 'Укажите ФИО',
  text: 'Введите текст',
  phone: 'Введите номер телефона',
  phone_format: 'Номер в формате +79991234567',
  email: 'Введите адрес почты',
  tin: 'Введите ИНН компании',
  email_format: 'Некорректный адрес почты',
  select: 'Выберите значение',
  checkbox: 'Выберите значение',
  radio: 'Выберите значение',
  company_name: 'Введите название компании',
  profile: 'Выберите профиль компании',
};

export const Placeholders:TStringsObj = {
  def: 'Введите значение',
  full_name: 'Фамилия Имя Отчество',
  text: 'Введите текст',
  phone: 'Введите номер телефона',
  tin: InputErrors.tin,
  email: 'Введите адрес почты',
  select: 'Выберите значение',
  checkbox: 'Выберите значение',
  radio: 'Выберите значение',
  company_name: 'Введите название компании',
  profile: 'Выберите профиль компании',
};

