import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import face from '../../assets/images/fillers/face.svg';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  contentClassName?: string,
  
  quote?: string,
  img?: string,
  text?: string,
  author?: string,
  position?: string,
}

const QuoteText:FC<PropsWithChildren<Props>> = ({
  className,
  contentClassName,

  quote = 'С 2019 года в стране действует специальный налоговый режим для самозанятых «Налог на профессиональный доход».',
  text = 'Его популярность среди москвичей остается высокой: в марте количество лиц с таким статусом увеличилось на 34,5 тысячи человек, ежемесячный прирост налогоплательщиков находится на уровне второй половины прошлого года. С начала 2022 года число самозанятых в столице выросло на 105 тысяч человек.',
  author = 'Владимир Ефимов',
  position = 'Заместитель Мэра Москвы в Правительстве Москвы по вопросам экономической политики и имущественно-земельных отношений',
  img = face,
}) => {


  return (
    <div className={cx(
      styles.quote,
      className,
    )}>
      <div className={styles.quoteText}>
        <h2>{quote}</h2>
        {text && <p>{text}</p>}
      </div>
      <div className={styles.quoteInfo}>
        <img
          src={img}
          alt=""
        />
        <div className={styles.author}>{author}</div>
        <div className={styles.position}>{position}</div>
      </div>
    </div>
  );
};

export default QuoteText;