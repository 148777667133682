import React, { FC } from 'react';
import { SiteContent } from '../../appConstants';
import {
  // BigHeader,
  BlockContent,
  Breadcrumbs,
  Contacts,
} from '../../components';
import { FeedbackForm } from '../../containers';
import { cropHref } from '../../helpers';

const ContactsPage: FC = () => {
  const { DocTitle, PageTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  return (
    <>
      <Breadcrumbs />
      <BlockContent title={PageTitle}>
        <Contacts fullPage />
      </BlockContent>

      <BlockContent title="Оставить заявку">
        <FeedbackForm />
      </BlockContent>
    </>
  );
};

export default ContactsPage;