import { useState } from 'react';
import { toast } from 'react-toastify';
import { SiteConfig } from '../appConstants';
import { AxiosRequestConfig } from 'axios';
import { apiClient } from '../utils';
import { RequestStatus } from '../types';

type HookConfig = {
  callback?: () => void,
  errback?: () => void,
};

const { showApiErrs } = SiteConfig;

const BaseRequest = <T = never> (
  hookConfig: HookConfig = {},
): {
  status: RequestStatus,
  data: T | null,
  request: (config: AxiosRequestConfig) => void,
} => {
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.INIT);
  const [data, setData] = useState<T | null>(null);

  const request = async (config: AxiosRequestConfig) => {
    setStatus(RequestStatus.REQUEST);
    try {
      const res = await apiClient(config);
      setData(res.data);
      if (hookConfig.callback) hookConfig.callback();
      setStatus(RequestStatus.SUCCESS);
    } catch (err) {
      if (hookConfig.errback) {
        hookConfig.errback();
      } else {
        showApiErrs && toast.error(`Упс, что-то пошло не так! Повторите позже.`);
      }
      setStatus(RequestStatus.ERROR);
    }
  };

  return {
    status,
    request,
    data,
  };
};

export default BaseRequest;