import axios from 'axios';
import { SiteConfig } from '../appConstants';

export const isLocalTest:boolean = window.location.href.includes('http://localhost');

export const envSwitch: () => string = () => {
  return (isLocalTest ? SiteConfig.prodAdress : process.env.REACT_APP_API_URL) as string;
};

export const apiClient = axios.create({
  baseURL: envSwitch(),
});

export const WIP:boolean = !isLocalTest && SiteConfig.wip;