import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
  DetailPage,
  GalleryPage,
  // Page404,
  SearchPage,
  WorkInProgress,
} from '../../pages';
import { WIP } from '../../utils';
import { RouteNames, publicRoutes } from '../../router';

const AppRouter = () => {
  return (
    <Routes>
      {WIP ? <>
        <Route path={"*"} element={<WorkInProgress />} />
        <Route path="/" element={<WorkInProgress />} />
      </> : <>
        {publicRoutes.map((route) => route.isActive &&
          <Route
            key={route.path}
            path={route.path}
            element={<route.component />}
          />
          )}
        <Route
          path="/search/*"
          element={<SearchPage />}
        />
        <Route
          path="/media/*"
          element={<DetailPage />}
        />
        <Route
          path="/gallery/*"
          element={<GalleryPage />}
        />
        <Route
          path="*"
          element={<Navigate replace to={RouteNames.PAGE404} />}
        />
      </>}
    </Routes>
  );
};

export default AppRouter;