import React, { FC, PropsWithChildren } from 'react';
import styles from './styles.module.scss';

type Props = {
  title: string,
}

const SupportBlock:FC<PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  return (
    <div className={styles.block2}>
      <h3 className={styles.block2Title}>{title}</h3>
      {children}
    </div>
  );
};

export default SupportBlock;