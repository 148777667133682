/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
import React, { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  SearchItem,
  Paginate,
} from '../../components';
import { useRequest } from '../../hooks';
import { TSearchData, TOpener } from '../../types';
import styles from './styles.module.scss';
import { useLocation } from 'react-router';

type Props = {
  type?: TOpener,
  requestUrl?: string,
  shortCard?: boolean,
  noLimits?: boolean,
  query?: string,
}

const PAGE_SIZE = 9;

type Params = {
  page: number,
};
const initialValues:Params = {
  page: 0
};

const SearchList:FC<Props> = ({
  requestUrl = 'noLink',
  shortCard = false,
  type,
  query,
  noLimits,
}) => {
  const {
    data,
    request,
  } = useRequest<TSearchData>();

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik<Params>({
    initialValues,
    onSubmit: (params) => {
      const { page } = params;
      if (query && query?.length > 3) {
        request({
          method: 'get',
          url: requestUrl,
          params: {
            limit: PAGE_SIZE,
            offset: page * PAGE_SIZE,
            q: query,
          },
        });
      }
    },
  });

  useEffect(() => {
    if (query && query?.length > 3) {
      const reqObj = noLimits ?
        {
          url: requestUrl,
          params: {
            q: query,
          }
        } : {
          url: requestUrl,
          params: {
            q: query,
            limit: PAGE_SIZE,
          },
        };
      request(reqObj);
    }
  }, []);

  const listOfData:any[] = data?.results ? data?.results.Article : [];

  return (
    <section>
      {query && <div className={styles.totalCount}>Результатов по запросу: {listOfData.length || 0}</div>}
      {/* <div className={styles.totalCount}>Результатов по запросу: {data?.overall_total || 0}</div> */}

      <div className={styles.mediaContent}>
        {listOfData.length > 0 && listOfData.map((item, id) => 
          <SearchItem
            item={{
              ...item,
              date: item.publish_date,
              tags: item.tag,
            }}
            type={type}
            key={id}
            shortCard={shortCard}
          />)
        }
      </div>

      {/* {!noLimits &&<Paginate
        pageSize={PAGE_SIZE}
        page={values.page}
        count={data?.overall_total || 0}
        onChange={(selected) => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          handleChange({
            target: {
              name: 'page',
              value: selected,
            },
          });
          handleSubmit();
        }}
      />} */}
    </section>
  );
};

export default SearchList;