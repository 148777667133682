import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  title?: string,
  visible?: boolean,
  className?: string,
  classPopUp?: string,
  onClose?: () => void,
}

const SidePopUp:FC<PropsWithChildren<Props>> = ({
  title = '',
  visible = false,
  className,
  classPopUp,
  onClose = () => {},
  children,
}) => {
  const onCloseHandler = () => {
    if (onClose) onClose();
  }

  return (
    <>
      {visible && <div
        className={styles.bg}
        onClick={() => onCloseHandler()}
      />}

      <div className={cx(
        styles.sidePopUp,
        classPopUp,
        {[styles.hidden]: !visible},
      )}>
        <div
          className={styles.close}
          onClick={() => onCloseHandler()}
        />
        {title && <h3 className={styles.title}>{title}</h3>}
        <div className={className}>
          {children}
        </div>
      </div>
    </>
  );
};

export default SidePopUp;