import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { SiteContent, URL } from '../../appConstants';
import { BlockContent, Breadcrumbs, SearchLine } from '../../components';
import { MediaGrid, SearchList } from '../../containers';
import { cropHref } from '../../helpers';
import styles from './styles.module.scss';

type Props = {}

const ExperiencePage:FC<Props> = () => {
  const { DocTitle, PageTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  const query = new URLSearchParams(useLocation().search).get('query');

  return (
    <>
      <Breadcrumbs />
      <BlockContent
        contentClass={styles.content}
        title={PageTitle}
      >
        <div>
          <SearchLine
            value={query || ''}
          />

          <SearchList
            requestUrl={URL.SEARCH.GET}
            query={query || ''}
            type='link'            
            noLimits
          />
        </div>
      </BlockContent>
    </>
  );
};

export default ExperiencePage;