import React, { FC } from 'react';
import cx from 'classnames';
import RPaginate from 'react-paginate';
import styles from './styles.module.scss';

type Props = {
  count: number,
  pageSize: number,
  page?: number,
  className?: string,
  onChange: (value: number) => void,
};

const Paginate: FC<Props> = ({
  count,
  page = undefined,
  className,
  pageSize,
  onChange,
}) => (
  count !== 0 && count > pageSize ? (
    <RPaginate
      forcePage={page}
      pageCount={Math.ceil(count / pageSize)}
      pageRangeDisplayed={5}
      marginPagesDisplayed={5}
      nextLabel=''
      previousLabel=''
      containerClassName={cx(styles.container, className)}
      pageLinkClassName={styles.btn}
      pageClassName={styles.link}
      activeLinkClassName={styles.active}
      previousLinkClassName={styles.label}
      nextLinkClassName={styles.label}
      previousClassName={styles.prev}
      nextClassName={styles.next}
      disabledClassName={styles.disabledLabel}
      onPageChange={({ selected }) => onChange(selected)}
    />
  ) : <></>
);

export default Paginate;
