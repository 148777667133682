import React, { FC, useEffect, useMemo } from 'react';
import { SiteContent, URL } from '../../appConstants';
import {
  Breadcrumbs,
  AccentBlock,
  BlockContent,
  RadialChart,
} from '../../components';
import {
  Advantages,
  FeedbackPopUp,
  GetSupport,
  SupportSteps,
  TargetSupHeader,
  Warranty,
} from '../../containers';
import { useRequest } from '../../hooks';
import { TBacicReq, TMainData, TSupTargAll } from '../../types';
import { cropHref } from '../../helpers';
import styles from './styles.module.scss';

type Props = {}

const TargetedSupportPage:FC<Props> = () => {
  const { DocTitle, PageTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  const { data: sup, request: getSup } = useRequest<TBacicReq<TSupTargAll>>();
  useEffect(() => {
    getSup({ url: URL.ADDRESS_SUPPORT.GET })
  }, [])

  const { data: main, request: getMain } = useRequest<TBacicReq<TMainData>>();
  useEffect(() => {
    getMain({ url: URL.MAIN_PAGE.GET })
  }, [])

  const GBlocks = useMemo(() => (
    { mainData }: { mainData?: TMainData }
  ) => {
    const data = [{
      accent: mainData?.numbers_minimum_security || '',
      text: 'Минимальное обеспечение ежегодного роста производительности на средних и крупных предприятиях Москвы',
    },{
      accent: mainData?.number_urban_enterprises || '',
      text: 'Городскoe предприятиe станет его участником к 2024 году',
    }];
    return (
      <div className={styles.gBlocks}>
        {data.map((item, id) => item.accent && 
          <AccentBlock
            key={id}
            accent={item.accent}
            text={item.text}
          />
        )}
      </div>
    )
  }, []);

  return (
    <>
      <Breadcrumbs />
      <BlockContent>
        <TargetSupHeader
          title={PageTitle}
          descr={sup?.results[0].description}
          titleChild={<>
            <div className={styles.openerWrap}>
              <FeedbackPopUp
                openerTitle='Подать заявку'
                popUpTitle='Подать заявку'
                buttonClassName={styles.opener}
              />
            </div>
            <GBlocks
              mainData={main?.results[0]}
            />
          </>
          }
        >
          <RadialChart
            max={sup?.results[0].digits_for_diagram_max}
            cur={sup?.results[0].digits_for_diagram_current}
          />
        </TargetSupHeader>

        <SupportSteps
          list={sup?.results[0].support}
        />
        <Warranty />
        <GetSupport />
        <Advantages/>
        
      </BlockContent>
    </>
  );
};

export default TargetedSupportPage;
