import React from 'react';
import { Menu, BlockContent, Search } from '../../components';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../router';
import siteLogo from '../../assets/images/asr_site_logo_norm.svg';
import styles from './styles.module.scss';

const Header = () => {

  return (
    <>
      <BlockContent
        wrapperClass={styles.header}
        contentClass={styles.content}
      >
        <Link to={RouteNames.MAIN}>
          <img src={siteLogo} alt="" />
        </Link>

        <div className={styles.rightSide}>
          <Menu />
          <Search />
        </div>
      </BlockContent>
    </>
  );
};

export default Header;