import React, { FC, useEffect, useState } from 'react';
import { SiteContent, URL } from '../../appConstants';
import { BlockContent, Breadcrumbs } from '../../components';
import { MediaGrid, TagsControl } from '../../containers';
import { cropHref } from '../../helpers';
import { useRequest } from '../../hooks';
import { TBacicReq, TTag } from '../../types';


type Props = {}

const MediaPage:FC<Props> = () => {
  const { DocTitle, PageTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);
  
  const { data: tags, request } = useRequest<TBacicReq<TTag>>();
  useEffect(() => {
    request({ url: URL.TAG.GET })
  }, [])

  const [ tagId, setTag ] = useState<number>(0);
  const clickHandler = (id: number) => {
    setTag(id);
  }

  const MediaList = () => {
    return (
      <MediaGrid
        requestUrl={URL.ARTICLE.GET}
        paramsUrl={tagId > 0 ? { tag: tagId, } : {}}
        type='link'
      />
    )
  };

  return (
    <>
      <Breadcrumbs />
      <BlockContent
        title={PageTitle}
        customTitle={<TagsControl
          tags={tags?.results}
          activeId={tagId}
          onClick={clickHandler}
        />}
      >
        {tagId !== 4 && <MediaList />}

        {tagId === 4 && <MediaGrid
          requestUrl={URL.GALLERY.GET}
          type='gallery'
        />}

      </BlockContent>
    </>
  );
};

export default MediaPage;