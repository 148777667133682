import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { SiteContent } from '../../appConstants';
import { phoneRefactor } from '../../utils';
import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  className?: string,
  fullPage?: boolean,
}

const Contacts:FC<Props> = ({
  className,
  fullPage = false,
}) => {
  const { Phone, Mail, Address, Socials} = SiteContent;

  const InfoLine:FC<PropsWithChildren<{title: string}>> = ({
    title,
    children,
  }) => (
    <div className={styles.infoLine}>
      <div className={styles.subTitle}>{title}:</div>
      {children}
    </div>
  );

  const SocialLinks = () => (
    <div className={styles.socials}>
      {Socials.map((Social) => Social.active && 
        <Link
          key={Social.name}
          to={Social.link}
          target='_blank'
          className={styles.rounded}
        >
          <img src={Social.image} alt="" />
        </Link>
      )}
    </div>
  );

  const FullContacts = () => {
    return (
      <div
        className={cx(
          className,
          styles.fullContacts,
        )}
      >
        <SocialLinks />

        <div className={styles.info}>
          <InfoLine title='Телефон'>
            <a href={`tel:${Phone}`}>
              {phoneRefactor(Phone)}
            </a>
          </InfoLine>
          <InfoLine title='Почта'>
            <a href={`mailto:${Mail}`}>
              {Mail}
            </a>
          </InfoLine>
          <InfoLine title='Адрес'>
            {Address}
          </InfoLine>
        </div>
      </div>
    )
  }

  const FooterContacts = () => {
    return (
      <div
        className={cx(
          className,
          styles.contacts,
        )}
      >
        <a href={`tel:${Phone}`}>
          {phoneRefactor(Phone)}
        </a>
        <a
          className={cx({[styles.big]: fullPage})}
          href={`mailto:${Mail}`}
        >
          {Mail}
        </a>
        <div>
          {Address}
        </div>
        <SocialLinks />
      </div>
    )
  }

  return fullPage ? 
    <FullContacts /> :
    <FooterContacts />
  ;
};

export default Contacts;