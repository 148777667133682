import React, { FC } from 'react';
import HTMLContent from '../HTMLContent';
import styles from './styles.module.scss';

type Props = {
  text: string,
}

const InfoText:FC<Props> = ({
  text,
}) => {
  return (
    <HTMLContent
      className={styles.infoText}
      content={text}
    />
  );
};

export default InfoText;