/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  MediaItem,
} from '../../components';
import PageSwitcher from './PageSwitcher';
import { useRequest } from '../../hooks';
import { TBacicReq, TMedia } from '../../types';
import { URL } from '../../appConstants';
import styles from './styles.module.scss';

type Props = {
}

const PAGE_SIZE = 3;

const MainMediaGrid:FC<Props> = () => {
  const [ page, setPage ] = useState<number>(0);

  const { data, request: getArticles, status } = useRequest<TBacicReq<TMedia>>();
  useEffect(() => {
    getArticles({
      url: URL.ARTICLE.GET,
    });
  }, []);

  const Articles:TMedia[] | undefined = useMemo(() => {
    return data?.results.filter(item =>
        item.is_active && item.publish_on_main_page
      );
  }, [data])

  const start:number = useMemo(() => page*PAGE_SIZE, [page])

  return (
    <section>
      <div className={styles.mediaContent}>
        {status === 'SUCCESS' && Articles?.length &&
          Articles?.slice(start, start+PAGE_SIZE).map((item, id) => 
            <MediaItem
              item={{
                ...item,
                image: item.preview_photo,
                date: item.publish_date,
              }}
              type='link'
              key={id}
            />
          )
        }

        {status === 'ERROR' && <div>Нет данных</div>}

        {status !== 'ERROR' && status !== 'SUCCESS' && <div>Загрузка...</div>}
      </div>
      <PageSwitcher
        page={page}
        count={Articles?.length || 0}
        pageSize={PAGE_SIZE}
        onChange={(id) => setPage(id)}
      />
    </section>
  );
};

export default MainMediaGrid;