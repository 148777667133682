// author: Victor K.
import { RouteNames } from '../router';
import {
  TStringsObj,
  TSocial,
} from '../types';
import icon_ok from '../assets/images/ext_links/icon_ok.svg';
import icon_tg from '../assets/images/ext_links/icon_tg.svg';
import icon_vk from '../assets/images/ext_links/icon_vk.svg';
import { SiteConfig } from './siteConfig';

const DefTitle:string = 'Агентство Стратегического Развития';
const Domain:string = SiteConfig.prodAdress;
const Mail:string = 'info@stratagency.moscow';
const Phone:string = '+74955266574';
const Address:string = '125009, Россия, г. Москва, Большой Кисловский переулок, 6';

const Socials:TSocial[] = [
  {
    name: 'VK',
    link: '//vk.com/',
    image: icon_vk,
    active: false,
  },{
    name: 'OK',
    link: '//ok.com/',
    image: icon_ok,
    active: false,
  },{
    name: 'TG',
    link: '//telegram.org/',
    image: icon_tg,
    active: true,
  },
];

const PagesInfo:TStringsObj = {
  def : {
    DocTitle: DefTitle,
    PageTitle: 'Страница без названия',
  },
  [RouteNames.MAIN] : {
    DocTitle: DefTitle,
    PageTitle: 'Главная',
  },
  [RouteNames.FOR_COMPANY] : {
    DocTitle: `Компаниям | ${DefTitle}`,
    PageTitle: 'Компаниям',
  },
  [RouteNames.EVENTS] : {
    DocTitle: `Мероприятия | ${DefTitle}`,
    PageTitle: 'Мероприятия',
  },
  [RouteNames.MEDIA] : {
    DocTitle: `Медиа | ${DefTitle}`,
    PageTitle: 'Медиа',
  },
  [RouteNames.ABOUT] : {
    DocTitle: `О нас | ${DefTitle}`,
    PageTitle: 'О нас',
  },
  [RouteNames.EXPERIENCE] : {
    DocTitle: `Лучшие практики | ${DefTitle}`,
    PageTitle: 'Лучшие практики',
  },
  [RouteNames.CONTACTS] : {
    DocTitle: `Контакты | ${DefTitle}`,
    PageTitle: 'Контакты',
  },
  [RouteNames.JURISTIC_DOCUMENTS] : {
    DocTitle: `Юридическая информация | ${DefTitle}`,
    PageTitle: 'Юридические документы',
  },
  [RouteNames.TARGETED_SUPPORT] : {
    DocTitle: `Адресная мера поддержки | ${DefTitle}`,
    PageTitle: 'Адресная мера поддержки',
  },
  [RouteNames.SEARCH] : {
    DocTitle: `Поиск | ${DefTitle}`,
    PageTitle: 'Поиск',
  },
  [RouteNames.SYSTEMIC_SUPPORT] : {
    DocTitle: `Системные меры поддержки | ${DefTitle}`,
    PageTitle: 'Системные меры поддержки',
  },
  [RouteNames.GALLERY] : {
    DocTitle: `Медиа | ${DefTitle}`,
    PageTitle: 'Медиа',
  },
  [RouteNames.CARRIER] : {
    DocTitle: `Карьера | ${DefTitle}`,
    PageTitle: 'Карьера',
  },


  [RouteNames.POLICY] : {
    title: `Политика конфиденциальности | ${DefTitle}`,
  },
  'project': (title:string = '') => `${title} | ${DefTitle}`,
};

export const SiteContent = {
  Domain,
  Mail,
  Phone,
  Address,
  Socials,
  PagesInfo,
};