import React, { FC } from 'react';
import { TBanner } from '../../types';
import { ExternalLink } from '../../components';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  data?: TBanner[],
}

const Banners:FC<Props> = ({
  data,
}) => {
  return data ? (
    <div className={styles.bannersBlock}>
      {data?.map((item) => item.size !== 'L' && 
        <ExternalLink
          className={cx(
            styles.banner,
            styles[item.size],
          )}
          fileLink={`//${item.link}`}
          title=''
        >
          <img
            src={item.image}
            alt=""
          />
        </ExternalLink>
      )}
    </div>
  ) : <></>;
};

export default Banners;