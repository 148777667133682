import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  accent: string,
  text: string,
}

const AccentBlock:FC<Props> = ({
  className,
  accent,
  text,
}) => {
  return (<div
    className={cx(
      styles.accentBlock,
      className,
    )}
  >
    <b>{accent}</b>
    {text}
  </div>
  );
};

export default AccentBlock;