import { ITags } from "../types";

export const Filt_Tags = (tagsObj: ITags[] = [], tagsId: number[]) => {
  let newArr:string[] = [];
  tagsId.map(i => {
    const item = tagsObj.find(it => it.id === i);
    if (item) newArr.push(item.title);
    return false;
  })

  return newArr;
}

export const Filt_Active = (items?:any[]) => items !== undefined ? items.filter(item => item.is_active) : [];
