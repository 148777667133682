import React, { useEffect } from 'react';
import { URL } from '../../appConstants';
import JuricDoc from '../../components/JuricDoc';
import { Filt_Active } from '../../helpers';
import { useRequest } from '../../hooks';
import { TBacicReq, TDoc } from '../../types';
import styles from './styles.module.scss';

const Documents = () => {
  const { data, request } = useRequest<TBacicReq<TDoc>>();
  useEffect(() => {
    request({ url: URL.DOCUMENTS.GET })
  }, [])

  return (
    <div className={styles.docsList}>
      {Filt_Active(data?.results).map((it, id) =>
        <JuricDoc
          key={id}
          title={it.title}
          fileLink={it.file}
        />
      )}
    </div>
  );
};

export default Documents;