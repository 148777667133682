import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  title: string,
  className?: string,
}

const BigHeader:FC<Props> = ({
  title,
  className,
}) => {
  return (
    <h1
      className={cx(
        className,
        styles.header,
      )}
    >
      {title}
    </h1>
  );
};

export default BigHeader;