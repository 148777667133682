import React, { FC, useEffect, useMemo } from 'react';
import Moment from 'react-moment';
import { SiteContent, URL } from '../../appConstants';
import {
  BlockContent,
  Breadcrumbs,
  DateComp,
  GalleryItems,
  Tag,
} from '../../components';
import { cropHref, cropHrefs } from '../../helpers';
import { useRequest } from '../../hooks';
import { TGallery } from '../../types';
import styles from './styles.module.scss';

type Props = {}

const GalleryPage:FC<Props> = () => {
  const { DocTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  const { data, request } = useRequest<TGallery>();
  useEffect(() => {
    const id = cropHrefs().pop();
    if (cropHrefs().length === 1) window.location.replace('/media');
    if (id) request({url: URL.GALLERY.GET_ID(+id)});
  }, []);

  const Article = useMemo(() => data, [data])

  return (
    <>
      <Breadcrumbs
        additional={Article?.title}
      />
      <BlockContent
        titleClass={styles.blockContent}
        title={Article?.title}
      >
        <div className={styles.meta}>
          {Article?.date_of_publish && 
            <DateComp className={styles.date}>
              <Moment format='DD.MM.YYYY' date={Article?.date_of_publish}/>
            </DateComp>
          }
          <Tag className={styles.tag}>{Article?.tag.title}</Tag>
        </div>

        <GalleryItems
          items={Article?.pictures}
        />

       </BlockContent>
    </>
  );
};

export default GalleryPage;