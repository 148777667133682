import React from 'react';
import { InfoText } from '../../components';
import SupportBlock from '../SupportBlock';
import img from '../../assets/images/fillers/_FillerImage1.png';
import cx from 'classnames';
import styles from './styles.module.scss';
import { ZeroNum } from '../../helpers';

const list = [
  {
    title: 'Предприятие, зарегистрированное и (или) осуществляющее свою деятельность (имеющее производственную площадку) в Москве',
    img: img,
  },{
    title: 'Предприятие относится к одной из приоритетных отраслей, а именно: обрабатывающее производство, сельское хозяйство, транспорт, строительство, торговля',
    img: img,
  },{
    title: 'Выручка предприятия за предыдущий год должна быть не менее 400 млн рублей',
    img: img,
  },{
    title: 'Доля участия налоговых резидентов иностранных государств в уставном (складочном) капитале предприятия должна быть не выше 50%',
    img: img,
  },
]

const GetSupport = () => {
  return (
    <SupportBlock
      title='Кто может получить поддержку'
    >
      <div className={styles.supportGroups}>
        <div className={styles.groups}>
          {list.map((item, id) =>
            <div
              key={id}
              className={cx(
                styles.group,
                {[styles.active]: id === 1},
              )}
            >
              <b>{ZeroNum(id)}</b>
              {item.title}
            </div>
          )}
        </div>
        
        <div className={styles.preview} />
      </div>

      <InfoText
        text={`
          Для того чтобы получить адресную поддержку бесплатно, необходимо подать заявку на сайте
          
          <a
            title='сайт "Производительность.рф"'
            target="_blank"
            rel="noreferrer"
            href='//Производительность.рф'
          >Производительность.рф</a>
          
          с указанием региона участия (г. Москва)
        `}
      />
    </SupportBlock>
  );
};

export default GetSupport;
