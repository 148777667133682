import React, { FC, PropsWithChildren } from 'react';
import { HTMLContent } from '../../../components';
import { TEmployee } from '../../../types';
import SidePopUp from '../Base';
import styles from './styles.module.scss';

type Props = {
  visible?: boolean,
  item: TEmployee,
  onClose?: () => void,
}

const EmployeePopUp:FC<PropsWithChildren<Props>> = ({
  visible = false,
  item,
  onClose = () => {},
}) => {
  return (
    <SidePopUp
      visible={visible}
      classPopUp={styles.employeeWrap}
      onClose={() => onClose()}
    >
      <div className={styles.employeePopUp}>
        <img className={styles.bigPic} src={item?.photo} alt="" />
        <div>
          <div className={styles.PPname}>
            {item?.full_name}
          </div>
          
          <div className={styles.PPposition}>
            {item?.description}
          </div>
          
          <img className={styles.minPic} src={item?.photo} alt="" />

          <HTMLContent
            className={styles.PPpositionDescr}
            content={item?.position_description || ''}
          />
        </div>
      </div>
    </SidePopUp>
  );
};

export default EmployeePopUp;