import React, { FC, useEffect, useMemo } from 'react';
// import { RouteNames } from '../../../router';
import { AnchorLink, ExternalLink } from '../../components';
import cx from 'classnames';
import styles from './styles.module.scss';
import { URL } from '../../appConstants';
import { useRequest } from '../../hooks';
import { TBacicReq, TCarrier } from '../../types';

type Props = {
  openMobile?: boolean,
}

const routes = {
  MAIN: '/',
  FOR_COMPANY: '/for-company',
  EVENTS: '/events',
  MEDIA: '/media',
  ABOUT: '/about',
  EXPERIENCE: '/experience',
  CONTACTS: '/contacts',
  PAGE404: '/oops-404',
  FEEDBACK: '/feedback',
  POLICY: '/policy',
  JURISTIC_DOCUMENTS: '/juristic-documents',
  TARGETED_SUPPORT: '/targeted-support',
  SYSTEMIC_SUPPORT: '/systemic-support',
  CARRIER: '/carrier',
}

const FooterMenu:FC<Props> = ({
  openMobile = false,
}) => {
  
  const { data, request: getArticles } = useRequest<TBacicReq<TCarrier>>();
  useEffect(() => {
    getArticles({
      url: URL.CARRIER.GET,
    });
  }, []);
  
  const MenuItems = useMemo(() => [
    {
      href: routes.TARGETED_SUPPORT,
      title: 'Адресная мера поддержки',
    },{
      href: routes.SYSTEMIC_SUPPORT,
      title: 'Системные меры поддержки',
    },{
      href: routes.EVENTS,
      title: 'Мероприятия',
    },{
      href: data?.results.filter(item => item.is_active)[0]?.file || '',
      title: 'Карьера',
      component: ExternalLink,
    },{
      href: routes.JURISTIC_DOCUMENTS,
      title: 'Юридическая информация',
    },{
      href: routes.MEDIA,
      title: 'Медиа',
    },{
      href: routes.ABOUT,
      title: 'О нас',
    },{
      href: routes.EXPERIENCE,
      title: 'Лучшие практики',
    },{
      href: routes.CONTACTS,
      title: 'Контакты',
    },
  ], [data]);

  return (
    <div className={cx(
      styles.footerMenu,
      {[styles.openMobile]: openMobile}
    )}>
      {MenuItems.map((link) => link.component ?
        <link.component
          key={link.title}
          className={styles.menuItem}
          title={link.title}
          fileLink={link.href}
        />
      :
        <AnchorLink
          key={link.title}
          className={styles.menuItem}
          to={link.href}
          onClick={() => {}}
        >
          {link.title}
        </AnchorLink>
      )}
    </div>
  );
};

export default FooterMenu;