import React from 'react';
import SupportBlock from '../SupportBlock';
import icon1 from '../../assets/images/icon_adv1_100.svg';
import icon2 from '../../assets/images/icon_adv2_100.svg';
import icon3 from '../../assets/images/icon_adv3_100.svg';
import icon4 from '../../assets/images/icon_adv4_100.svg';
import icon5 from '../../assets/images/icon_adv5_100.svg';
import styles from './styles.module.scss';

const list = [
  {
    title: '100% финансирование городом участия московских производителей',
    img: icon1,
  },{
    title: 'Увеличение экономического эффекта (прибыли) предприятия',
    img: icon2,
  },{
    title: 'Формирование новой культуры производительности труда',
    img: icon3,
  },{
    title: 'Уменьшение незавершенного производства',
    img: icon4,
  },{
    title: 'Снижение времени протекания производственных процессов',
    img: icon5,
  },
]

const Advantages = () => {
  return (
    <SupportBlock
      title='Наши преимущества'
    >
      <div className={styles.advantages}>
        {list.map((item, id) =>
          <div
            key={id}
          >
            <img src={item.img} alt="" />
            {item.title}
          </div>
        )}
      </div>
    </SupportBlock>
  );
};

export default Advantages;