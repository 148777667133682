import React, { FC } from 'react';
// import { RouteNames } from '../../../router';
import { AnchorLink } from '../../../components';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  openMobile?: boolean,
  onClick?: () => void,
}

const routes = {
  MAIN: '/',
  FOR_COMPANY: '/for-company',
  TARGETED_SUPPORT: '/targeted-support',
  SYSTEMIC_SUPPORT: '/systemic-support',
  EVENTS: '/events',
  MEDIA: '/media',
  ABOUT: '/about',
  EXPERIENCE: '/experience',
  CONTACTS: '/contacts',
  PAGE404: '/oops-404',
  FEEDBACK: '/feedback',
  POLICY: '/policy',
}

interface menuLink {
  href: string,
  title: string,
}

interface menuList extends menuLink {
  sublinks?: menuLink[],
}


const MenuItems:menuList[] = [
  {
    href: routes.FOR_COMPANY,
    title: 'Компаниям',
    sublinks: [
      {
        href: routes.TARGETED_SUPPORT,
        title: 'Адресная мера поддержки',
      },{
        href: routes.SYSTEMIC_SUPPORT,
        title: 'Системные меры поддержки',
      },
    ]
  },{
    href: routes.EVENTS,
    title: 'Мероприятия',
  },{
    href: routes.MEDIA,
    title: 'Медиа',
  },{
    href: routes.ABOUT,
    title: 'О нас',
  },{
    href: routes.EXPERIENCE,
    title: 'Лучшие практики',
  },{
    href: routes.CONTACTS,
    title: 'Контакты',
  },
]

const MenuLinks:FC<Props> = ({
  openMobile = false,
  onClick,
}) => {

  const ForCompanies = () => {
    return MenuItems[0].sublinks?.length ? (
      <div className={styles.opened}>
        {MenuItems[0].sublinks?.map((link) => 
          <AnchorLink
            key={link.title}
            className={styles.menuItem}
            to={link.href}
            onClick={onClick}
            >
            {link.title}
          </AnchorLink>
        )}
      </div>
    ) : <></>
  }
  return (
    <div className={cx(
      styles.menu,
      {[styles.openMobile]: openMobile}
    )}>
      <div className={styles.desktop}>
        <div className={cx(
          styles.menuItem,
          styles.dropdown,
          )}>
          Компаниям
          <ForCompanies />
        </div>
      </div> 

      <div className={styles.mobile}>
        <ForCompanies />
      </div>


      {MenuItems.map((link, id) => id !== 0 && 
        <AnchorLink
          key={link.title}
          className={styles.menuItem}
          to={link.href}
          onClick={onClick}
        >
          {link.title}
        </AnchorLink>
      )}
    </div>
  );
};

export default MenuLinks;