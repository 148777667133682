import React, { FC, useEffect } from 'react';
import { SiteContent, URL } from '../../appConstants';
import { BlockContent, Breadcrumbs } from '../../components';
import { OwnerCard, Personal } from '../../containers';
import { cropHref, Filt_Active } from '../../helpers';
import { useRequest } from '../../hooks';
import { TBacicReq, TEmployee } from '../../types';

type Props = {}

const PersDir = [
  {
    title: 'Ген директор',
    main: true,
    id: 1,
  },{
    title: 'Руководители направлений',
    main: false,
    id: 3,
  },{
    title: 'Эксперты',
    main: false,
    id: 2,
  }
]


const AboutPage:FC<Props> = () => {
  const { DocTitle, PageTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  const { data, request } = useRequest<TBacicReq<TEmployee>>();
  useEffect(() => {
    request({ url: URL.EMPLOYEE.GET })
  }, [])

  return (
    <>
      <Breadcrumbs />
      <BlockContent title={PageTitle}>
      
        <OwnerCard
          item={data?.results.find(item => item.position.id === 1)}
        />
      
        {PersDir.map((pers, id) => !pers.main &&
          <Personal
            key={id}
            title={pers.title}
            personal={Filt_Active(data?.results).filter(empl => empl.position.id === pers.id)}
          />
        )}

      </BlockContent>
    </>
  );
};

export default AboutPage;
