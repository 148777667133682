import React, { useEffect, useMemo } from 'react';
import { MediaItem } from '../../components';
import SupportBlock from '../SupportBlock';
// import fillerImg from '../../assets/images/fillers/_FillerImage2.jpg';
import styles from './styles.module.scss';
import { TBacicReq, TDirection } from '../../types';
import { useRequest } from '../../hooks';
import { URL } from '../../appConstants';

const SupportWays = () => {
  const { data, request } = useRequest<TBacicReq<TDirection>>();
  useEffect(() => {
    request({ url: URL.DIRECTIONS.GET })
  }, [])

  return useMemo(() => ( 
    <SupportBlock
      title='Направления'
    >
      <div className={styles.supportWays}>
        {data?.results.map((item, id) =>
          <MediaItem
            type='div'
            item={{
              ...item,
              descr: item.short_description,
              document: item.link_for_application,
              colorBtnText: 'Отправить заявку',
              bwBtnText: 'Оператор',
              btnsFirst: true,
            }}
            key={id}
            shortCard
          />
        )}
      </div>
    </SupportBlock>
  ), [data])
};

export default SupportWays;