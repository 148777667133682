import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  item?: string,
  title?: string,
  placeholder?: string,
  value?: string | number,
  onChange: (e: React.ChangeEvent<any>) => void,
  error?: string,
  max?: number,
  errorWarn?: boolean,
  reqired?: boolean,
}

const Input:FC<Props> = ({
  item,
  placeholder,
  className,
  value,
  onChange = () => {},
  error,
  max = 250,
  errorWarn = false,
  reqired = false,
}) => {
  return (
    <div className={cx(
      styles.field,
      className,
      {[styles.warningError]: error && errorWarn,}
    )}>
      <input
        className={cx({[styles.filled]: !!value})}
        id={item}
        name={item}
        type={item}
        onChange={onChange}
        value={value}
        placeholder={placeholder?.concat(reqired ? '*' : '')}
        maxLength={max}
        required={reqired}
      />
      
      {error &&
        <div className={styles.error}>{error}</div>
      }
    </div>
  );
};

export default Input;