import React, { FC, PropsWithChildren, ReactElement } from 'react';
import styles from './styles.module.scss';

type Props = {
  title: string,
  descr?: string,
  titleChild?: ReactElement,
}

const TargetSupHeader:FC<PropsWithChildren<Props>> = ({
  title,
  descr,
  titleChild,
  children,
}) => {
  return (
    <div className={styles.headerBlock}>
      <div className={styles.headerText}>
        <h2>{title}</h2>
        {descr &&
          <div className={styles.descr}>
            {descr}
          </div>
        }
        {titleChild}
      </div>
      {children}
    </div>
  );
};

export default TargetSupHeader;