import React, { FC, ChangeEvent, KeyboardEvent, useState } from 'react';
import { RouteNames } from '../../../router';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  value?: string,
}

const SearchLine:FC<Props> = ({
  className,
  value = '',
}) => {
  const [ searchStr, setSearchStr ] = useState<string>(value);
  const [ searchValid, setSearchValid ] = useState<boolean>(false);

  const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchStr(e.target.value);
    setSearchValid(searchStr.trim().length > 2);
  }

  const sumbitHandler = () => {
    if (searchValid) window.location.href = RouteNames.SEARCH.concat(`/?query=${searchStr}`)
  }

  const keyupHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      sumbitHandler()
    }
  }

  return (
    <div className={cx(
      styles.searchLine,
      className,
    )}>
      <input
        className={styles.searchInput}
        type="text"
        value={searchStr}
        onChange={(e) => searchHandler(e)}
        onKeyUp={(e) => keyupHandler(e)}
        placeholder='Поиск'

      />
      <div
        className={cx(
          styles.searchButton,
          {[styles.disabled]: !searchValid}
        )}
        onClick={() => sumbitHandler()}
      >
        Поиск
      </div>
    </div>
  );
};

export default SearchLine;