import React, { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  image?: string,
  // video?: string,
  className?: string,
}

const BigPoster:FC<Props> = ({
  image,
  // video,
  className,
}) => {
  return (
    <div className={cx(
      styles.bigImg,
      className
    )}>
      <img
        src={image}
        alt=''
      />
    </div>
  );
};

export default BigPoster;