import React, { FC, useState } from 'react';
import { SiteContent, URL } from '../../appConstants';
import { BlockContent, Breadcrumbs } from '../../components';
import { MediaGrid } from '../../containers';
import { cropHref } from '../../helpers';

type Props = {}

const ExperiencePage:FC<Props> = () => {
  const { DocTitle, PageTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  return (
    <>
      <Breadcrumbs />
      <BlockContent title={PageTitle}>
        <MediaGrid
          requestUrl={URL.BEST_PRACTICE.GET}
          shortCard
          type='div'
        />
      </BlockContent>
    </>
  );
};

export default ExperiencePage;