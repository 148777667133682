import React, { FC } from 'react';
import { SiteContent } from '../../appConstants';
import { BlockContent, Breadcrumbs } from '../../components';
import { Documents } from '../../containers';
import { cropHref } from '../../helpers';

type Props = {}

const JuristicDocumentsPage:FC<Props> = () => {
  const { DocTitle, PageTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  return (
    <>
      <Breadcrumbs />
      <BlockContent title={PageTitle}>
        <Documents />
      </BlockContent>
    </>
  );
};

export default JuristicDocumentsPage;