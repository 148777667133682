import React from 'react';
import SupportBlock from '../SupportBlock';
import styles from './styles.module.scss';
import AccentBlock from '../../components/AccentBlock';



const list = [
  {
    accent: '5%',
    title: 'Минимальное обеспечение ежегодного роста производительности труда на средних и крупных предприятиях Москвы к 2024 году',
  },{
    accent: '560+',
    title: 'Предприятий столицы получат адресную поддержку с 2022 по 2024 год',
  },{
    accent: '109',
    title: 'Предприятий столицы будет охвачено уже в 2022 году',
  },
]

const Warranty = () => {
  return (
    <SupportBlock
      title='Мы гарантируем'
    >
      <div className={styles.advantages}>
        {list.map((item, id) =>
          <AccentBlock
            key={id}
            accent={item.accent}
            text={item.title}
          />
        )}
      </div>
    </SupportBlock>
  );
};

export default Warranty;