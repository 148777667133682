import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { scrollToItem } from '../../helpers';

type Props = {
  to: string,
  className?: string,
  onClick?: () => void,
}

const AnchorLink:FC<PropsWithChildren<Props>> = ({
  to,
  className,
  onClick,
  children
}) => {
  const { pathname } = window.location;

  const clickHandler = () => {
    if (onClick) onClick();
    const targEl = to.split('#')[1];
    scrollToItem(targEl, pathname !== '/');
  };

  useEffect(() => {
    const item = sessionStorage.getItem('scrollTo');
    if (pathname === '/' && !!item) {
      scrollToItem(item);
    }
  })

  return (
    <Link
      className={className}
      to={to}
      onClick={() => clickHandler()}
    >
      {children}
    </Link>
  );
};

export default AnchorLink;