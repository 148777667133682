import React, { FC } from 'react';
import { SiteContent } from '../../appConstants';
import { cropHref } from '../../helpers';
import BlockContent from '../BlockContent';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  additional?: string,
}

const Breadcrumbs:FC<Props> = ({
  additional,
}) => {
  const { PageTitle } = SiteContent.PagesInfo[cropHref()];

  const red = () => cropHref() === '/gallery' ? '/media' : cropHref();
  

  return (
    window.location.pathname !== '/' ? 
      <BlockContent
        wrapperClass={styles.wrapper}
        contentClass={styles.content}
      >
        <a href="/">
          Главная
        </a>
        <a
          href={red()}
          className={!additional ? styles.active : ''}
        >
          / {PageTitle}
        </a>
        {additional && <span
          className={cx(
            styles.active,
            styles.additional,
          )}
        >
          / {additional}
        </span>}
      </BlockContent>
    : <></>
  );
};

export default Breadcrumbs;