// author: Victor K.
import { TSocial } from '../../types';
import eco_mosc_bw from '../../assets/images/partners/eco_mosc_bw.svg';
import depr_bw from '../../assets/images/partners/depr_bw.svg';
import national_bw from '../../assets/images/partners/national_bw.svg';
import asr_bw from '../../assets/images/partners/asr_bw.svg';
import styles from './styles.module.scss';

const PARTNERS:TSocial[] = [
  {
    name: 'Экономика Москвы',
    link: 'https://economy.mos.ru/',
    image: eco_mosc_bw,
    active: true,
  },{
    name: 'Департамент экономической политики и развития города Москвы',
    link: '	https://www.mos.ru/depr/',
    image: depr_bw,
    active: true,
  },{
    name: 'Агенство стратегического развития',
    link: 'https://stratagency.moscow',
    image: asr_bw,
    active: true,
  },{
    name: 'ФЦК',
    link: 'https://xn--b1aedfedwqbdfbnzkf0oe.xn--p1ai/',
    image: national_bw,
    active: true,
  },
];

const Partners = () => {
  return (
    <div className={styles.partners}>
      {PARTNERS.map((item, id) => item.active && 
        <a
          key={id}
          href={item.link}
          target='_blank'
          rel="noreferrer"
        >
          <img src={item.image} alt={item.name} />
        </a>
      )}
    </div>
  )
}

export default Partners;
