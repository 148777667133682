import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { BlockContent } from '../../components';
import styles from './styles.module.scss';

const Page404: FC = () => {
  return (
    <BlockContent
      wrapperClass={styles.errPage}
      contentClass={styles.errContent}
    >
      <div className={styles.text}>
        <div>Ошибка 404.</div>
        <div>Страница не найдена</div>
      </div>
        <Link
          className={styles.link}
          to='/'
        >Вернуться на главную</Link>
    </BlockContent>
  );
};

export default Page404;