import { Header, Footer, Reqs } from '../../containers';
import { isLocalTest } from '../../utils';
import { toast } from 'react-toastify';
import AppRouter from './appRouter';

const App = () => {
  toast.configure();
  
  return (
    <>
      {/* {isLocalTest && <Reqs />} */}
      <Header />
      <AppRouter />
      <Footer />
    </>
  );
}

export default App;
