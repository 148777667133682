/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
import React, { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  MediaItem,
  Paginate,
} from '../../components';
import { useRequest } from '../../hooks';
import { TBacicReq, TOpener, TStringsObj } from '../../types';
import styles from './styles.module.scss';

type Props = {
  type?: TOpener,
  requestUrl?: string,
  paramsUrl?: TStringsObj,
  shortCard?: boolean,
  noLimits?: boolean,
  items?: any[];
}

const PAGE_SIZE = 9;

type Params = {
  page: number,
};
const initialValues:Params = {
  page: 0
};

const MediaGrid:FC<Props> = ({
  requestUrl = 'noLink',
  paramsUrl = {},
  shortCard = false,
  type,
  noLimits,
  items,
}) => {
  const {
    data,
    request,
    status,
  } = useRequest<TBacicReq>();

  const {
    values,
    handleChange,
    handleSubmit,
  } = useFormik<Params>({
    initialValues,
    onSubmit: (params) => {
      const { page } = params;
      request({
        method: 'get',
        url: requestUrl,
        params: {
          ...paramsUrl,
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
        },
      });
    },
  });

  useEffect(() => {
    if (!items?.length) {
      const limit = !!items?.length ? undefined : PAGE_SIZE;
      request({
        url: requestUrl,
        params: {
          ...paramsUrl,
          limit,
        },
      });
    }
  }, []);

  const listOfData:any[] = (items?.length ? items : data?.results) || [];

  return (
    <section>
      <div className={styles.mediaContent}>
        {(status === 'SUCCESS' || !!items?.length) &&
          listOfData.map((item, id) => 
            <MediaItem
              item={{
                ...item,
                image: item.preview_photo,
                date: item.publish_date || item.date_of_publish,
              }}
              type={type}
              key={id}
              shortCard={shortCard}
            />
          )
        }

        {((data && data?.count === 0)) && <div>
          {(status !== 'ERROR' && status !== 'SUCCESS') && 'Загрузка...'}
          {status === 'SUCCESS' && 'Нет данных'}
        </div>}
      </div>
      <Paginate
        pageSize={PAGE_SIZE}
        page={values.page}
        count={data?.count || 0}
        onChange={(selected) => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          handleChange({
            target: {
              name: 'page',
              value: selected,
            },
          });
          handleSubmit();
        }}
      />
    </section>
  );
};

export default MediaGrid;