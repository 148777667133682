// author: Victor K.
export const SiteConfig:{
  siteId: number,
  prodAdress: string,
  useTestData: boolean,
  showApiErrs: boolean,
  wip: boolean, //in 'dev' flag
} = {
  siteId: 1,
  prodAdress: 'https://dev-app.stratagency.moscow/',
  useTestData: true,
  showApiErrs: true,
  wip: false,
};