import React, { FC } from 'react';
import { BlockContent } from '../../components';
import styles from './styles.module.scss';

const WorkInProgress: FC = () => {
  return (
    <BlockContent wrapperClass={styles.errPage}>
      <div className={styles.text}> Сайт в разработке </div>
    </BlockContent>
  );
};

export default WorkInProgress;