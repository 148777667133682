// author: Victor K.
import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  isActive: boolean,
  onClick: () => void,
}

const Burger:FC<Props> = ({
  isActive = false,
  onClick,
}) => {
  return (
    <div
      className={cx(
        styles.burger,
        {[styles.isActive]: isActive},
      )}
      onClick={onClick}
    >
      <span>Меню</span>
    </div>
  );
};

export default Burger;