import React, { FC, useState } from 'react';
import { TEmployee } from '../../types';
import { EmployeePopUp } from '../SidePopUp';
import styles from './styles.module.scss';

type Props = {
  title?: string,
  personal?: TEmployee[],
}

const Personal:FC<Props> = ({
  title,
  personal,
}) => {
  
  const EmployeeItem = ({
    item,
  }:{item: TEmployee}) => {
    const [ isShowDetail, setIsShowDetail ] = useState<boolean>(false);
    
    return (
      <>
        <div
          className={styles.persItem}
          onClick={() => setIsShowDetail(true)}
        >
          <div className={styles.name}>
            {item?.full_name}
            <div className={styles.photo}>
              <img src={item?.photo} alt="" />
            </div>
          </div>
          <div className={styles.position}>
            {item?.description}
          </div>
          <div className={styles.arrow}></div>
        </div>

        <EmployeePopUp
          visible={isShowDetail}
          onClose={() => {
            console.log(isShowDetail);
            setIsShowDetail(!isShowDetail)
          }}
          item={item}
        />
      </>
    )
  }


  return (personal?.length ?
    <div className={styles.personalList}>
      <h3>{title || 'Персонал'}</h3>
      <div>
        {personal?.map((item, id) =>
          <EmployeeItem
            item={item}
            key={id}
          />
        )}
      </div>
    </div> : <></>
  );
};

export default Personal;