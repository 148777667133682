import { ActionTypes, IDataAction } from "../../types";
import { Dispatch } from "redux";
import axios from "axios";
import { toast } from 'react-toastify';

const URL:string = 'https://jsonplaceholder.typicode.com/users';
// const URL:string = 'https://jsonplaceholder.typicode.com/users/dwa';

export const fetchData = () => {
  return async (dispatch: Dispatch<IDataAction>) => {
    try {
      dispatch({
        type: ActionTypes.GET_DATA
      })
      const response = await axios.get(URL)
      dispatch({
        type: ActionTypes.SET_DATA_SUCCESS,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.SET_DATA_ERROR,
        payload: 'Ошибочка вышла'
      })
      toast.error('Ошибочка вышла');
    }
  }
}