import React, { FC } from 'react';
import { HTMLContent } from '../../components';
import { TEvent } from '../../types';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  item?: TEvent,
  className?: string,
}

const EventCard:FC<Props> = ({
  item,
  className,
}) => {
  return (
    <div className={cx(
      styles.eventInfo,
      className,
    )}>
      <h3>{item?.title}</h3>
      <img
        className={styles.poster}
        src={item?.image}
        alt={item?.title}
      />
      <HTMLContent
        content={item?.description || ''}
      />

      {!!item?.partner.length && 
        <div className={styles.partnersBlock}>
          <h4>Партнеры мероприятия:</h4>
          <div className={styles.partners}>
            {item?.partner.map((item, id) => 
              <a
                key={id}
                href={item.link}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className={styles.partner}
                  src={item.photo}
                  alt={item.title}
                />
              </a>
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default EventCard;