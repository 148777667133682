// author: Victor K.
export const URL = {
  'ROOT': '/',
  'DOCUMENTS': {
    GET: '/api/documents/',
  },
  'APPLICATION': {
    POST: '/api/application/',
  },
  'EMPLOYEE': {
    GET: '/api/employee/',
  },
  'BEST_PRACTICE': {
    GET: '/api/bestpractice/',
  },
  'PARTNER': {
    GET: '/api/partner/',
  },
  'EVENT': {
    GET: '/api/event/',
  },
  'MAIN_PAGE': {
    GET: '/api/main-page-data/',
  },
  'SUPPORT_MEASURES': {
    GET: '/api/support-measures/',
  },
  'DIRECTIONS': {
    GET: '/api/directions/',
  },
  'GALLERY': {
    GET: '/api/gallery/',
    GET_ID: (id: number) => `/api/gallery/${id}`,
  },
  'ARTICLE': {
    GET: '/api/article/',
    GET_ID: (id: number) => `/api/article/${id}`,
  },
  'TAG': {
    GET: '/api/tag/',
  },
  'ADDRESS_SUPPORT': {
    GET: '/api/address-support/',
  },
  'ABOUT_SUPPORT': {
    GET: '/api/about-support/',
  },
  'CARRIER': {
    GET: '/api/career-doc/',
  },
  'SEARCH': {
    GET: '/api/search/',
  },

  'TEST': {
    'GET': 'https://randomuser.me/api/',
  },
};