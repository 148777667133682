const toStr = (date?: Date | string) => {
  const tmp =
    (date ? new Date(date) : new Date()).toLocaleDateString().split('.');
  return `${tmp[2]}-${tmp[1]}-${tmp[0]}`
}

export const momenTime = {
  nowStr: toStr(),
  now: new Date(),
  toStr: (date: Date | string) => toStr(date),
  toObg: (date?: string) => date ? new Date(date) : new Date(),
  isToday: (date: string) => toStr() === toStr(new Date(date)),
}