import { IRoute } from "../types";
import {
  Page404,
  ContactsPage,
  MainPage,
  PolicyPage,
  EventsPage,
  AboutPage,
  ExperiencePage,
  ForCompanyPage,
  MediaPage,
  CarrierPage,
  SearchPage,
  SystemicSupportPage,
  TargetedSupportPage,
  JuristicDocumentsPage,
  GalleryPage,
} from "../pages";

export enum RouteNames {
  MAIN = '/',
  FOR_COMPANY = '/for-company',
  EVENTS = '/events',
  MEDIA = '/media',
  ABOUT = '/about',
  EXPERIENCE = '/experience',
  CONTACTS = '/contacts',
  SEARCH = '/search',
  JURISTIC_DOCUMENTS = '/juristic-documents',
  TARGETED_SUPPORT = '/targeted-support',
  SYSTEMIC_SUPPORT = '/systemic-support',
  CARRIER = '/carrier',
  GALLERY = '/gallery',
  FEEDBACK = '/feedback',
  POLICY = '/policy',
  PAGE404 = '/oops-404',
}

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.MAIN,
    component: MainPage,
    isActive: true,
  },{
    path: RouteNames.FOR_COMPANY,
    component: ForCompanyPage,
    isActive: true,
  },{
    path: RouteNames.EVENTS,
    component: EventsPage,
    isActive: true,
  },{
    path: RouteNames.MEDIA,
    component: MediaPage,
    isActive: true,
  },{
    path: RouteNames.GALLERY,
    component: GalleryPage,
    isActive: true,
  },{
    path: RouteNames.ABOUT,
    component: AboutPage,
    isActive: true,
  },{
    path: RouteNames.EXPERIENCE,
    component: ExperiencePage,
    isActive: true,
  },{
    path: RouteNames.CONTACTS,
    component: ContactsPage,
    isActive: true,
  },{
    path: RouteNames.POLICY,
    component: PolicyPage,
    isActive: true,
  },{
    path: RouteNames.JURISTIC_DOCUMENTS,
    component: JuristicDocumentsPage,
    isActive: true,
  },{
    path: RouteNames.TARGETED_SUPPORT,
    component: TargetedSupportPage,
    isActive: true,
  },{
    path: RouteNames.SYSTEMIC_SUPPORT,
    component: SystemicSupportPage,
    isActive: true,
  },{
    path: RouteNames.CARRIER,
    component: CarrierPage,
    isActive: true,
  },{
    path: RouteNames.SEARCH,
    component: SearchPage,
    isActive: true,
  },{
    path: RouteNames.PAGE404,
    component: Page404,
    isActive: true,
  }
]