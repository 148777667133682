import React, { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { ArrowLink } from '../../components';

type Props = {
  title?: string,
  link?: string,
  className?: string,
  contentClassName?: string,
}

const BigTitleBlock:FC<PropsWithChildren<Props>> = ({
  title,
  link = '',
  className,
  contentClassName,
  children,
}) => {
  return (
    <div className={cx(
      styles.bigTitleBlock,
      className,
    )}>
      {title && <h2 className={styles.title}>
        {title}
        {!!link && <ArrowLink link={link} />}
      </h2>}

      {contentClassName
        ? <div className={contentClassName}>
            {children}
          </div>
        : children
      }
    </div>
  );
};

export default BigTitleBlock;