import React, { FC, useMemo } from 'react';
import { byPrior, ZeroNum } from '../../helpers';
import { TSupTarg } from '../../types';
import SupportBlock from '../SupportBlock';
import styles from './styles.module.scss';

type Props = {
  list?: TSupTarg[],
}

const SupportSteps:FC<Props> = ({
  list,
}) => {
  return useMemo(() => (
    <SupportBlock
      title='В чем заключается адресная поддержка'
    >
      <div className={styles.advantages}>
        {list?.length && byPrior(list)?.map((item, id) =>
          <div
            className={styles.item}
            key={id}
          >
            <b>{ZeroNum(id)}</b>
            {item.title}
          </div>
        )}
      </div>
    </SupportBlock>
  ), [list]);
};

export default SupportSteps;