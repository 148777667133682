import React, { FC, useEffect } from 'react';
import { SiteContent, URL } from '../../appConstants';
import { BlockContent, Breadcrumbs } from '../../components';
import { Advantages, GetSupport, SupportHeader, SupportWays, TargetSupHeader, Warranty } from '../../containers';
import { cropHref } from '../../helpers';
import { useRequest } from '../../hooks';
import { TBacicReq, TSupMeas } from '../../types';
import styles from './styles.module.scss';

type Props = {}

const SystemicSupportPage:FC<Props> = () => {
  const { DocTitle, PageTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  const { data: support_measures, request: support_measuresReq } = useRequest<TBacicReq<TSupMeas>>();
  useEffect(() => {
    support_measuresReq({ url: URL.SUPPORT_MEASURES.GET })
  }, [])

  // const SupItem = ({item}:{item: TSupMeas}) => (
  //   <div className={styles.supItem}>
  //     {item.description}
  //   </div>
  // )

  return (
    <>
      <Breadcrumbs />
      <BlockContent>
        <TargetSupHeader
          title={PageTitle}
          descr={support_measures?.results[0].description}
        />

        <SupportWays />
        <Warranty />
        <GetSupport />
        <Advantages/>
        
      </BlockContent>
    </>
  );
};

export default SystemicSupportPage;