import React, { FC } from 'react';
// import { Link } from 'react-router-dom';
import {
  BlockContent,
  Contacts,
  Partners,
  Copyright,
  FooterMenu,
} from '../../components';
import styles from './styles.module.scss';

const Footer:FC = () =>
  <BlockContent
    id='Contacts'
    wrapperClass={styles.footer}
  >
    <div className={styles.content}>
      <Partners />
      <FooterMenu />
      <Contacts />
    </div>
    <Copyright />
  </BlockContent>

export default Footer;