import React, { FC, useEffect, useMemo, useState } from 'react';
import { SiteContent, URL } from '../../appConstants';
import { Calendar, BlockContent, Breadcrumbs } from '../../components';
import { EventsList, TagsControl } from '../../containers';
import { cropHref, momenTime } from '../../helpers';
import { useRequest } from '../../hooks';
import { TEvent, TBacicReq, TTag } from '../../types';
import styles from './styles.module.scss';

type Props = {}

const Tags:TTag[] = [
  {
    created_at: 'string',
    id: 1,
    is_active: true,
    title: 'Конференции',
  },{
    created_at: 'string',
    id: 2,
    is_active: true,
    title: 'Семинары/обучения',
  },
]

const FilterById:{
  [index:number]: string,
} = {
  0: '',
  1: 'Конференция',
  2: 'Семинар/Обучение',
}

const EventsPage:FC<Props> = () => {
  const { DocTitle, PageTitle } = SiteContent.PagesInfo[cropHref()];
  document.title = DocTitle;
  window.scrollTo(0,0);

  const [ selected, setSelect ] = useState<TEvent[]>();
  const [ selectedDate, setSelectDate ] = useState<Date>(new Date());

  const [ tagId, setTag ] = useState<number>(0);
  const clickHandler = (id: number) => {
    setTag(id);
  }
  const { data, request } = useRequest<TBacicReq<TEvent>>();
  useEffect(() => {request({ url: URL.EVENT.GET })}, []);

  const NewEvents = useMemo(() => {
    const tag = FilterById[tagId];
    const fix:any[] = [];
    data?.results.filter((item) => tag ? item.tag === tag : item).forEach(item => {
      item.is_active && fix.push(
        {
          ...item,
          start: new Date(item.date),
          end: new Date(item.date),
        }
      )
    })
    return fix;
  }, [tagId, data]);

  useEffect(() => {
    setSelect(NewEvents.filter(item => momenTime.isToday(item.date)));
  }, [NewEvents]);

  return (
    <>
      <Breadcrumbs />
      <BlockContent
        title={PageTitle}
        customTitle={<TagsControl
          tags={Tags}
          activeId={tagId}
          onClick={clickHandler}
        />}
      >
        <div className={styles.content}>
          <Calendar
            events={NewEvents}
            onNavigate={(e) => {
              setSelectDate(e);
              setSelect(NewEvents.filter(item => item.date === momenTime.toStr(e)));
            }}
          />

          <EventsList
            events={selected}
            selectedDate={selectedDate}
          />
        </div>
      </BlockContent>
    </>
  );
};

export default EventsPage;
