// author: Victor K.
import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Input, PolicyBtn, Select } from '../../components';
import { InputErrors, URL } from '../../appConstants';
import { useRequest } from '../../hooks';
import { TApplication } from '../../types';
import { toast } from 'react-toastify';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  formStyle?: 'small', 
}

type TString = {[index:string]: string | number};

const fields:{
  nameKey: string,
  title: string,
  reqired?: boolean,
  min?: number,
  max?: number,
  select?: boolean,
}[] = [
  {
    nameKey: 'full_name',
    title: 'Фамилия Имя Отчество',
    reqired: true,
    max: 100,
  },{
    nameKey: 'mail',
    title: 'Email',
    reqired: true,
  },{
    nameKey: 'phone',
    title: 'Номер телефона',
    max: 12,
    reqired: true,
  },{
    nameKey: 'tin',
    title: 'ИНН компании',
    reqired: true,
    max: 13,
  },{
    nameKey: 'company_name',
    title: 'Название компании',
    reqired: true,
  },{
    nameKey: 'profile',
    title: 'Профиль компании',
    select: true,
    reqired: true,
  },{
    nameKey: 'comments',
    title: 'Опишите вопрос',
  }
]

const Profiles = [
  'Производство',
  'Сельское хозяйство',
  'Строительство',
  'Торговля',
  'Транспорт',
]

const FeedbackForm:FC<Props> = ({
  formStyle = '',
}) => {
  const { request: sendData } = useRequest<TApplication>({
    callback: () => {
      resetForm();
      toast.success('Данные отправлены')
    },
  });
  const [ assign, setAssign ] = useState<boolean>(false);
  const [ warning, setWarning ] = useState<boolean>(false);

  const validationSchema = Yup.object({
    full_name: Yup.string()
      .min(2, InputErrors.min(2))
      .max(100, InputErrors.max(100))
      .required( InputErrors.full_name),
    company_name: Yup.string()
      .min(0, InputErrors.min(0))
      .required( InputErrors.company_name),
    phone: Yup.string()
      .length(12, InputErrors.phone_format)
      .matches(/(\+7)[0-9]{10}/, {
        message: InputErrors.phone_format,
        excludeEmptyString: false,
      }).required( InputErrors.phone),
    tin: Yup.string()
      .matches(/[0-9]/, {
        message: InputErrors.tin,
        excludeEmptyString: false,
      })
      .min(10, InputErrors.min(10))
      .max(13, InputErrors.tin)
      .required( InputErrors.tin),
    mail: Yup.string()
      .email(InputErrors.email_format)
      .required(InputErrors.email),
    message: Yup.string().max(250, InputErrors.max(250)),
    profile: Yup.string().required( InputErrors.profile),
  })
  
  const {
    values,
    handleChange,
    handleSubmit,
    isValid,
    resetForm,
    errors,
   } = useFormik({
    initialValues: (() => {
      const obj:TString = {};
      fields.forEach(({nameKey}) => obj[nameKey] = '');
      return obj;
    })(),
    validationSchema,
    onSubmit: () => {
      sendData({
        method: 'POST',
        url: URL.APPLICATION.POST,
        data: {
          ...values,
        },
      });
    }
  });

  const onHandleSubmit = () => {
    if (!isValid) {
      toast.error('Исправьте ошибки заполнения формы');
      setWarning(true);
    }
  }

  const onHandleChange = (e: React.ChangeEvent<any>) => {
    if (warning && isValid) setWarning(false);
    handleChange(e);
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={cx(
        styles.feedbackForm,
        {[styles.small]: formStyle === 'small'}
      )}
    >
      {fields.map(({
        nameKey,
        title,
        reqired,
        max,
        select,
      }) => !select ?
        <Input
          className={styles.field}
          key={nameKey}
          placeholder={title}
          item={nameKey}
          value={values[nameKey]}
          error={errors[nameKey]}
          errorWarn={warning}
          onChange={(e) => onHandleChange(e)}
          max={max || 250}
          reqired={!!reqired}
        /> :
        <Select
          className={styles.field}
          key={nameKey}
          placeholder={title}
          item={nameKey}
          items={Profiles}
          value={values[nameKey]}
          error={errors[nameKey]}
          onChange={(e) => onHandleChange(e)}
          max={max || 250}
          reqired={!!reqired}
        />
      )}

      <div className={styles.assign}>
        <input type="checkbox"
          name="assign"
          id=""
          checked={assign}
          required
          onChange={() => setAssign(!assign)}
        />
        <label htmlFor="assign">
          Нажимая отправить, вы соглашаетесь с <PolicyBtn title='политикой конфиденциальности'/>
        </label>
      </div>

      <div>
        <button
          className={styles.submit}
          type="submit"
          disabled={!isValid && !assign}
          onClick={onHandleSubmit}
        >
          Отправить заявку
        </button>
      </div>
    </form>
  );
};

export default FeedbackForm;