import React, { FC, useState } from 'react';
import {
  Calendar as ReactCalendar,
  dateFnsLocalizer,
} from 'react-big-calendar';
import {
  getDay,
  startOfWeek,
  parse,
  format,
} from 'date-fns';
import { IReactCalendarCEvent } from '../../types';
import { ru, enUS } from 'date-fns/locale';
import cx from 'classnames';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.css';
import styles from './styles.module.scss';

type Props = {
  height?: string,
  events?: IReactCalendarCEvent[],
  components?: object,
  className?: string,
  onNavigate?: (e:any) => void,
  onSelectEvent?: (e:any) => void,
}

const locales = {
  ru,
  enUS,
};

const messages = {
  today: 'Сегодня',
  previous: 'Назад',
  next: 'Вперед',
  month: 'Месяц',
  week: 'Неделя',
  day: 'День',
  agenda: 'По часам',
  noEventsInRange: 'Нет мероприятий',
};

const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
const days = [ 'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб' ];


const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Calendar:FC<Props> = ({
  height,
  events,
  components,
  onNavigate = () => {},
  onSelectEvent = () => {},
  className,
}) => {
  const [ show, setShow ] = useState<boolean>(false);

  return (
    <>
      <div className={styles.calendarExpander}>
        <span>Выберите дату в календаре:</span>
        <div
          className={styles.button}
          onClick={() => setShow(true)}
        >Календарь</div>
      </div>

      <div className={cx(
        styles.calendarWrap,
        {[styles.showInMobile]: show},
      )}>
        <div className={styles.calendarContainer}>
          <div
            className={styles.mobileControls}
          >
            <h3>Выберите дату:</h3>
            <div
              onClick={() => setShow(false)}
              className={styles.close}
            />
          </div>

          <ReactCalendar
            culture={'ru'}
            localizer={localizer}
            events={events}
            style={{ height }}
            formats={{
              monthHeaderFormat: (date) => 
                `${months[date.getMonth()]} ${date.getFullYear()}`,
              weekdayFormat: (date) => days[date.getDay()],
            }}
            className={cx(
              styles.calendar,
              className,
            )}
            views={{
              month: true,
            }}
            components={components}
            messages={messages}
            onNavigate={onNavigate}
            onSelectEvent={onSelectEvent}
          />
        </div>
      </div>
    </>
  );
};

export default Calendar;