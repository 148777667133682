import React, { FC, PropsWithChildren } from 'react';
import { HTMLContent, LinkButton } from '../../../components';
import { TItem } from '../../../types';
import SidePopUp from '../Base';
import styles from './styles.module.scss';

type Props = {
  visible?: boolean,
  title?: string,
  item: TItem,
  onClose?: () => void,
}

const MediaPopUp:FC<PropsWithChildren<Props>> = ({
  visible = false,
  title,
  item,
  onClose = () => {},
}) => {
  const {
    photo,
    document,
    description,
    link,

    colorBtnText,
    bwBtnText,
    btnsFirst,
  } = item;


  return (
    <SidePopUp
      visible={visible}
      title={title}
      onClose={() => onClose()}
    >
      {!btnsFirst && <img className={styles.photo} src={photo} alt="" />}
      
      <div className={styles.btns}>
        {document && <LinkButton
          to={document}
        >{colorBtnText || 'Скачать документ'}</LinkButton>}
        {link && <LinkButton
          variant='bw'
          to={link}
        >{bwBtnText || 'Узнать подробнее'}</LinkButton>}
      </div>

      {btnsFirst && <img className={styles.photo} src={photo} alt="" />}
      
      <HTMLContent
        content={description || 'нет данных'}
      />
    </SidePopUp>
  );
};

export default MediaPopUp;