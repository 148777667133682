// author: Victor K.
import { FC, PropsWithChildren } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  className?: string,
  title?: string,
  fileLink: string,
};

const ExternalLink:FC<PropsWithChildren<Props>> = ({
  className,
  title = '',
  fileLink,
  children,
}) => 
  <a
    className={cx(
      styles.externalLink,
      className,
    )}
    title={title}
    target="_blank"
    rel="noreferrer"
    href={fileLink}
  >
    {children || title}
  </a>

export default ExternalLink;

