import React, { FC, PropsWithChildren, useMemo } from 'react';
import { TTag } from '../../types';
import { Filt_Active } from '../../helpers';
import { Tag } from '../../components';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  tags?: TTag[],
  activeId?: number,
  onClick?: (id: number) => void,
}

// const ExcludeFilter:string[] = [
//   'Галерея',
// ];

const TagsControl:FC<PropsWithChildren<Props>> = ({
  tags,
  activeId,
  onClick = () => {},
  children,
}) => useMemo(() => {
  return Filt_Active(tags) ? (
    <div className={styles.controlWrapper}>
      <Tag
        className={cx(
          styles.tagFilter,
          {[styles.active]: activeId === 0},
        )}
        onClick={() => onClick(0)}
      >Все</Tag>
      
      {Filt_Active(tags)?.map((tag, id) => 
        <Tag
          className={cx(
            styles.tagFilter,
            {[styles.active]: activeId === tag.id},
          )}
          key={id}
          onClick={() => onClick(tag.id)}
        >{tag.title}</Tag>
      )}
    </div>
  ) : <></>
}, [tags, activeId]);

export default TagsControl;