export interface IDataState {
  data: any[];
  loading: boolean;
  error: null | string;
}

export enum ActionTypes {
  GET_DATA = "GET_DATA",
  SET_DATA_SUCCESS = "SET_DATA_SUCCESS",
  SET_DATA_ERROR = "SET_DATA_ERROR",
}

interface FetchGetData {
  type: ActionTypes.GET_DATA;
}
interface FetchSetDataSuccess {
  type: ActionTypes.SET_DATA_SUCCESS;
  payload: any[];
}
interface FetchSetDataError {
  type: ActionTypes.SET_DATA_ERROR;
  payload: string;
}

export type IDataAction = 
    FetchGetData
  | FetchSetDataSuccess
  | FetchSetDataError;