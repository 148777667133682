import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import styles from './styles.module.scss';

type Props = {
  to: string,
  variant?: 'color' | 'bw'
  className?: string,
}

const LinkButton:FC<PropsWithChildren<Props>> = ({
  to,
  variant = 'color',
  className,
  children
}) => {
  return (
    <a
      className={cx(
        className,
        styles.button,
        styles[variant],
      )}
      target='_blank'
      rel="noreferrer"
      href={to}
    >
      {children}
    </a>
  );
};

export default LinkButton;