import React, { FC } from 'react';
import { HTMLContent } from '../../components';
import { TEmployee } from '../../types';
import styles from './styles.module.scss';

type Props = {
  item?: TEmployee,
}

const OwnerCard:FC<Props> = ({
  item,
}) => {
  return (
    <div className={styles.owner}>
      <div className={styles.details}>
        <h3>{item?.full_name}</h3>
        <div className={styles.position}>
          {item?.description}
        </div>
        <HTMLContent
          className={styles.msg}
          content={item?.position_description || ''}
        />
      </div>
      <div className={styles.photo}>
        <img src={item?.photo} alt={item?.full_name} />
      </div>
    </div>
  );
};

export default OwnerCard;