import React, { FC, useMemo, useState } from 'react';
import { TPicture } from '../../types';
import cx from 'classnames';
import styles from './styles.module.scss';
import { byPrior } from '../../helpers';

type Props = {
  items?: TPicture[],
}

const GalleryItems:FC<Props> = ({
  items,
}) => {
  const [ activeId, setActive ] = useState<number>(0)

  const Images = useMemo(() => items && byPrior(items) as TPicture[], [items]);

  return (
    Images?.length ? <>
      <a
        href={Images?.[activeId]?.image}
        target='_blank'
        rel='noreferrer'
        title='Открыть в новой вкладке'
      >
        <img
          className={styles.activePicture}
          src={Images?.[activeId]?.image}
          alt=''
        />
      </a>

      <div className={styles.picturesList}>
        {Images?.map((item, id) =>
          <img
            key={id}
            className={cx(
              styles.picture,
              {[styles.active]: id === activeId},
            )}
            src={item.image}
            alt=''
            onClick={() => setActive(id)}
          />
        )}
      </div>
    </> : <div className={styles.noImages}>
      Нет изображений
    </div>
  );
};

export default GalleryItems;